import logo from './logo.svg';

import './App.css';

function App() {
  return (
    <div>
      HearTickets
    </div>
  );
}

export default App;
